import { h } from 'preact';
import { Router } from 'preact-router';

import Header from './header';

// Code-splitting is automated for `routes` directory
import Home from '../routes/home';
import Profile from '../routes/profile';
import UserInfo from "../routes/Meeting";


const Divider = () =>(
	<div style={{height:"20%",minHeight:70}}/>
)

const App = () => (
	<div className="background">
		<Header />
		<Divider/>
		<main>
			<Router>
				<Home path="/" />
				<UserInfo path="/userinfo"/>
			</Router>
		</main>
	</div>
);

export default App;
