import { h } from 'preact';
import { Link } from 'preact-router/match';
import style from './style.css';

const Header = () => {
  const handleScroll = () => {
    const header = document.querySelector('#header');
    if (window.pageYOffset > 0) {
      header.style.display = 'none';
    } else {
      header.style.display = 'block';
    }
  };

  // Add scroll event listener
  if (typeof window !== 'undefined') {
    window.addEventListener('scroll', handleScroll);
  }

  return (
    <header id="header" class={`${style.header} transparent`} style={{ display: window.pageYOffset > 0 ? 'none' : 'block' }}>
      <nav class={style.nav}>
        <Link activeClassName={style.active} href="/">
          Home
        </Link>
        <Link activeClassName={style.active} href="/profile">
          Talleres
        </Link>
        <Link activeClassName={style.active} href="/userinfo">
          Citas
        </Link>
      </nav>
    </header>
  );
};

export default Header;
